import * as React from "react"

import { faChevronRight, faEye, faPhone } from "@fortawesome/free-solid-svg-icons"
import {
  Quote,
  CardList,
  SimpleBanner,
  BannerTitle,
  TitledParagraph,
  HorizontalProfile,
  FaqList,
  Profile,
  CreativeContent,
} from "@launchacademy/voyager"
import { graphql } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import ReactMarkdown from "react-markdown"

import Configuration from "../../Configuration"
import { CourseScheduleList } from "../../components/courseSchedule/CourseScheduleList"
import { HalfPageParagraph } from "../../components/halfPageParagraphs/HalfPageParagraph"
import BookInfoCallButton from "../../components/infoCall/BookInfoCallButton"
import Layout from "../../components/layout"
import { Module } from "../../components/module/Module"
import { ModuleLearningObjective } from "../../components/module/ModuleLearningObjective"
import Seo from "../../components/seo"
import SyllabusModalButton from "../../components/syllabus/SyllabusModalButton"
import {
  cohortSchedule,
  collaborationContent,
  courseContent,
  tuitionContent,
  codedQuestions,
  courseColumns,
  textColumns,
  tuitionColumns,
  photos,
} from "../../pageData/programs/immersiveOnsitePageData"
import "./../css/program.css"
import { buildCohortProps } from "../../services/buildCohortProps"
import buildImage from "../../services/buildImage"
import "../../css/accent-images/program.css"

const ImmersiveOnsite = ({ data, location }) => {
  const courseContentWithImages = courseContent.map((content) => {
    const imageDataForContent = data[content.id]
    return { ...content, Image: buildImage(imageDataForContent) }
  })

  const cohorts = buildCohortProps(data.cohorts.nodes)

  const teamMemberListItems = (data.teamMemberSet?.team_members || []).map((teamMember) => {
    return (
      <HorizontalProfile
        key={teamMember.id}
        name={`${teamMember.firstName} ${teamMember.lastName}`}
        Image={buildImage(teamMember.headshot?.localFile)}
        currentTitle={teamMember.jobTitle}
        description={teamMember.bio}
        profileType="teamMember"
      />
    )
  })

  const collaborationContentWithImages = collaborationContent.map((content) => {
    const imageDataForContent = data[content.id]
    return { ...content, Image: buildImage(imageDataForContent) }
  })

  const tuitionContentWithImages = tuitionContent.map((content) => {
    const imageDataForContent = data[content.id]
    return { ...content, Image: buildImage(imageDataForContent) }
  })

  const featuredLauncher = data.launcherSet.featured_launchers[0]
  return (
    <Layout>
      <Seo
        title="Launch Academy's Immersive Software Development Program"
        description="Launch Academy offers a 8 week virtual, 10 week immersive program. Learn to code with JavaScript, Ruby on Rails, NodeJS, and ReactJS"
        pathname={location.pathname}
      />

      <section className="hero">
        <div className="hero__image-column">
          <div className="hero__image-container">
            <GatsbyImage
              image={getImage(data.topLeftHeader)}
              className="hero__image hero__image_sm"
              alt="Student reading digital article"
            />
          </div>
        </div>
        <div className="hero__supporting-content">
          <h2 className="hero__image_subtitle">Launch your career.</h2>
          <h1 className="hero__image_title">Then take it to the next level.</h1>
          <div className="hero__button-container">
            <BookInfoCallButton
              text="Schedule an Info Call"
              icon={faPhone}
              className="hero__button"
              location={location}
              size="lg"
            />
          </div>
          <div className="hero__button-container">
            <SyllabusModalButton
              text="Get the Syllabus"
              location={location}
              icon={faEye}
              secondary
              size="lg"
            />
          </div>
          <Quote
            quote="The curriculum is consistently updated to keep up with version updates and industry popularity."
            name="Amy Lieberman,"
            company="One Door"
          />
        </div>
      </section>
      <h2 className="text-center">Upcoming course schedule</h2>
      <CourseScheduleList cohortSchedule={cohorts} />
      <section className="program-features card-list-container mt-20 z-10">
        <h2 className="mb-10">
          Everything you need to get<br></br> hire-ready in just 18 weeks.
        </h2>
        <CardList content={courseContentWithImages} numberOfColumns={courseColumns} />
      </section>
      <SimpleBanner
        type="prose"
        className="z-0"
        BackgroundImage={buildImage(data.agileCurriculumBackground)}
      >
        <BannerTitle>
          An agile curriculum updated for every incoming class so your skills will always match the
          latest market demands.
        </BannerTitle>
        <SyllabusModalButton
          location={location}
          text="Get the Syllabus"
          icon={faEye}
          className="mt-5 lg:mt-10"
          placement="photo"
          secondary
          size="lg"
        />
      </SimpleBanner>
      <div className="module">
        <Module
          subtitle="Module 0"
          title="Course Prep &amp; Pre-work"
          location="Online"
          commitment="Part-Time"
          duration="3 Weeks"
          description="Get ready for your new career and develop advanced skills in a hands-on environment"
          Image={buildImage(data.coursePrepImage)}
        >
          <ModuleLearningObjective objectiveDescription="Confirm your interest in software engineering prior to paying tuition." />
          <ModuleLearningObjective objectiveDescription="Validate your love for coding prior to changing careers." />
          <ModuleLearningObjective objectiveDescription="Learn the basics to reduce imposter syndrome ahead of the program." />
        </Module>
        <Module
          subtitle="Module 1"
          title="Coding Fundamentals"
          location="Online"
          commitment="Part-Time"
          duration="8 Weeks"
          description="Get ready for your new career and develop advanced-level skills in a hands-on environment."
          Image={buildImage(data.codingFundamentalsImage)}
        >
          <ModuleLearningObjective objectiveDescription="Begin working collaboratively with fellow students while learning the basics." />
          <ModuleLearningObjective objectiveDescription="Get unstuck quickly with the help of our software engineering instructors." />
          <ModuleLearningObjective objectiveDescription="Option to pause your cohort and defer to the next one if it's moving too quickly." />
        </Module>
        <Module
          subtitle="Module 2"
          title="Software Engineering Immersive"
          location="On Campus"
          commitment="Full-Time"
          duration="10 Weeks"
          description="Learn the skills that today's engineering teams need."
          Image={buildImage(data.immersiveImage)}
        >
          <ModuleLearningObjective objectiveDescription="On-campus learning mirroring that of a software engineering apprenticeship." />
          <ModuleLearningObjective objectiveDescription="Build your portfolio with independent and collaborative projects." />
          <ModuleLearningObjective objectiveDescription="Dedicated career services curriculum to prepare you for job interviews." />
        </Module>
        <Module
          subtitle="Module 3"
          title="Career Advancement and Post-grad Support"
          location="Online and On Campus as Needed"
          commitment="Part-Time"
          duration="8 Weeks"
          description="After all of the preparation and study, you're ready to land your first job as a software developer."
          Image={buildImage(data.careerAdvancementImage)}
        >
          <ModuleLearningObjective objectiveDescription="Present your portfolio projects to interested employers seeking to hire graduates." />
          <ModuleLearningObjective objectiveDescription="Develop a job search strategy with our career services team - from application to interview, to offer and negotiation." />
          <ModuleLearningObjective objectiveDescription="‘Lifetime Access to Alumni Support’ provides you with quarterly curriculum updates so you can continue learning on the job and position yourself for promotions.  Come back to Launch Academy as a free workshare to build side projects with alumni and expand your local professional network." />
        </Module>
      </div>
      <div className="mb-20">
        <TitledParagraph
          className="titled-paragraph__container"
          title="Build production-ready portfolio apps"
        >
          <div className="card-list-container">
            <CardList
              cardType="feature"
              content={collaborationContentWithImages}
              numberOfColumns={textColumns}
            />
          </div>
        </TitledParagraph>
      </div>
      <SimpleBanner type="prose" BackgroundImage={buildImage(data.firstJobBackground)}>
        <BannerTitle>
          Helping you land your first job is the first step. Where most bootcamps end, we’re just
          getting started.
        </BannerTitle>
        <BookInfoCallButton
          text="Schedule an Info Call"
          icon={faPhone}
          className="hero__button pr-3 mt-5 lg:mt-10"
          location={location}
          size="lg"
        />
      </SimpleBanner>
      <div className="constrained-container">
        <div className="banner-with-creative creative-content__container banner-with-creative_white">
          <HalfPageParagraph
            title="Land the job. Learn for life."
            className="my-4"
            text="Learning doesn’t end when you land your first job. Launch graduates enjoy continuous curriculum access and updates, Career Services support and connections, and a robust alumni network - for life."
            subtitle="Kickstart your career with confidence"
            icon={faChevronRight}
          />
          {featuredLauncher && (
            <CreativeContent>
              <Profile
                name={`${featuredLauncher.firstName} ${featuredLauncher.lastName}`}
                Image={buildImage(featuredLauncher.headshot?.localFile)}
                currentTitle={featuredLauncher.currentJobTitle}
                currentEmployer={featuredLauncher.currentEmployer}
                formerTitle={featuredLauncher.previousTitle}
              />
            </CreativeContent>
          )}
        </div>
      </div>
      <div className="horizontal-profile-list__title constrained-container">
        <h2 className="mb-10">
          {" "}
          Learn to code the human way: Meet your team of full-time software engineers
        </h2>
        <div className="horizontal-profile-list__container">{teamMemberListItems}</div>
      </div>
      <div className="bg-teal pb-20 px-4">
        <div className="mx-4 md:pt-32 md:pb-8 constrained-container relative">
          <h2 className="mb-4 pt-8 md:mb-10">Flexible Tuition Options</h2>
          <p className="mr-12 md:mr-96">
            All accepted students enroll with a $1,000 refundable deposit to save their seat. You
            will have a one-time option to defer to a future cohort prior to week 8 should you want
            more time with the material. We offer $500 diversity scholarships for anyone who feels
            they have a background which is underrepresented in the software engineering field.
            Accepted students can earn an additional $250 scholarship upon successful completion of
            our free 3-week prep program.
          </p>
          <div className="banner__accent-image banner__accent-image_top-right">
            <GatsbyImage image={getImage(data.darkMeeting)} alt="Launchers Meeting" />
          </div>
        </div>
        <section className="card-list-container relative">
          <CardList
            cardType="feature"
            className="double-gap"
            content={tuitionContentWithImages}
            numberOfColumns={tuitionColumns}
          />
          <div className="banner__accent-image opacity-30 absolute -left-72 -bottom-24 z-0 hidden lg:block">
            <GatsbyImage image={getImage(data.computingAccent)} alt="Coding at Computer" />
          </div>
        </section>
      </div>
      <div className="faq-list container z-10 bg-white constrained-container">
        <h2 className="faq-list__header">Frequently Asked Questions</h2>
        <div className="faq-list-container">
          <FaqList
            questions={(data.faqSet?.frequently_asked_questions || []).map((question) => {
              return {
                title: question.question,
                description: <ReactMarkdown>{question.answer.data.answer}</ReactMarkdown>,
                id: question.id,
              }
            })}
          />
        </div>
      </div>
      <section className="bg-secondary-brand">
        <div className="hero">
          <div className="hero__supporting-content p-10">
            <h2 className="hero__image_title pb-10">Ready to design a better future?</h2>
            <div className="hero__button-container">
              <BookInfoCallButton
                text="Schedule an Info Call"
                icon={faPhone}
                className="banner__button pr-3"
                location={location}
                size="lg"
              />
            </div>
            <div className="hero__button-container">
              <SyllabusModalButton
                text="Get the Syllabus"
                location={location}
                icon={faChevronRight}
                size="lg"
                placement="banner"
                secondary
              />
            </div>
          </div>
          <div className="hero__image-column md:my-16">
            <div className="hero__image-container relative">
              <GatsbyImage
                image={getImage(data.launchAcademyShirts)}
                alt="Launch Academy T-Shirts"
                className="hero__accent-top-right"
              />
              <GatsbyImage
                image={getImage(data.groupPicImage)}
                className="hero__image hero__image_sm z-10 shadow-md"
                alt="Group picture in from of Launch Academy logo"
              />
              <GatsbyImage
                image={getImage(data.collaboratingBackground)}
                className="hero__accent-bottom-left"
                alt="Collaborating on software"
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ImmersiveOnsite

export const pageQuery = graphql`
  query {
    topLeftHeader: file(
      relativePath: { regex: "/programs/immersive-onsite-page/topLeftHeader.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          breakpoints: [210, 630]
          blurredOptions: { toFormat: WEBP }
          placeholder: BLURRED
        )
      }
    }
    computingAccent: file(
      relativePath: { regex: "/programs/immersive-onsite-page/topLeftHeader.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 690, height: 300)
      }
    }
    darkMeeting: file(relativePath: { regex: "/dark-meeting/" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 180, height: 250)
      }
    }
    order: file(relativePath: { regex: "/programs/immersive-onsite-page/order.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    tech: file(relativePath: { regex: "/programs/immersive-onsite-page/tech.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    instructors: file(
      relativePath: { regex: "/programs/immersive-onsite-page/agileCurriculumBackground.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    practice: file(relativePath: { regex: "/programs/immersive-onsite-page/topLeftHeader.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    job: file(relativePath: { regex: "/programs/immersive-onsite-page/job.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    employers: file(relativePath: { regex: "/programs/immersive-onsite-page/employers.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    agileCurriculumBackground: file(
      relativePath: { regex: "/programs/immersive-onsite-page/agileCurriculumBackground.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    coursePrepImage: file(
      relativePath: { regex: "/programs/immersive-onsite-page/coursePrepImage.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 720], width: 720)
      }
    }
    collaboratingBackground: file(
      relativePath: { regex: "/index-page/collaboratingBackground.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 220, height: 230)
      }
    }
    launchAcademyShirts: file(relativePath: { regex: "/launch-academy-shirts.png/" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 390, height: 370)
      }
    }
    codingFundamentalsImage: file(
      relativePath: { regex: "/programs/immersive-onsite-page/codingFundamentalsImage.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 720], width: 720)
      }
    }
    immersiveImage: file(
      relativePath: { regex: "/programs/immersive-onsite-page/immersiveImage.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 466], aspectRatio: 0.85, width: 466)
      }
    }
    careerAdvancementImage: file(
      relativePath: { regex: "/programs/immersive-onsite-page/careerAdvancementImage.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          breakpoints: [210, 720]
          aspectRatio: 0.9
          transformOptions: {}
          width: 720
        )
      }
    }
    capstoneProject: file(
      relativePath: { regex: "/programs/immersive-onsite-page/capstoneProject.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    teamProject: file(relativePath: { regex: "/programs/immersive-onsite-page/teamProject.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    firstJobBackground: file(
      relativePath: { regex: "/programs/immersive-onsite-page/firstJobBackground.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    profileImageOne: file(
      relativePath: { regex: "/programs/immersive-onsite-page/profileImageOne.png/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 300, height: 300)
      }
    }
    profileImageTwo: file(
      relativePath: { regex: "/programs/immersive-onsite-page/profileImageTwo.png/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 300, height: 300)
      }
    }
    profileImageThree: file(
      relativePath: { regex: "/programs/immersive-onsite-page/profileImageThree.png/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 300, height: 300)
      }
    }
    profileImageFour: file(
      relativePath: { regex: "/programs/immersive-onsite-page/profileImageFour.png/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 300, height: 300)
      }
    }
    upfront: file(relativePath: { regex: "/programs/immersive-onsite-page/upfront.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    afterGraduate: file(
      relativePath: { regex: "/programs/immersive-onsite-page/afterGraduate.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    topProgram: file(relativePath: { regex: "/programs/immersive-onsite-page/topProgram.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    groupPicImage: file(
      relativePath: { regex: "/programs/immersive-onsite-page/groupPicImage.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    launcherSet: strapiFeaturedLauncherSet(name: { eq: "program" }) {
      id
      featured_launchers {
        firstName
        currentJobTitle
        currentEmployer
        lastName
        headshot {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 300, height: 300)
            }
          }
        }
      }
    }
    teamMemberSet: strapiTeamSet(name: { eq: "program" }) {
      team_members {
        bio
        headshot {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: FIXED
                width: 220
                height: 220
                transformOptions: { grayscale: true }
              )
            }
          }
        }
        id
        jobTitle
        lastName
        firstName
      }
    }
    faqSet: strapiFrequentlyAskedQuestionSet(name: { eq: "program" }) {
      frequently_asked_questions {
        id
        answer {
          data {
            answer
          }
        }
        question
      }
    }
    cohorts: allStrapiCohort(
      sort: { order: ASC, fields: cohortBegins }
      filter: { showOnSite: { eq: true } }
    ) {
      nodes {
        name
        enrollmentEnds
        ignitionBegins
        preflightBegins
        cohortBegins
        cohortEnds
      }
    }
  }
`
